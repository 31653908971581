import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { customerSearchTypes } from 'actions/types'

const initialState = null
const baseType = customerSearchTypes.SEARCH_FOR_CUSTOMER

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType }),
  requestState: requestStateReducer(baseType),
})

export default reducer
