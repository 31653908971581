import { combineReducers } from 'redux'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import dataReducer from 'lib/reducers/dataReducer'
import { organisationTypes } from 'actions/types'

const initialState = null
const baseTypes = [
  organisationTypes.FETCH_ORGANISATION,
  organisationTypes.FETCH_ORGANISATION_FOR_PARTY
]

const reducer = combineReducers({
  organisationRequestState: requestStateReducer(organisationTypes.FETCH_ORGANISATION),
  partyOrganisationRequestState: requestStateReducer(organisationTypes.FETCH_ORGANISATION_FOR_PARTY),
  data: dataReducer({ initialState, baseType: baseTypes }),
})

export default reducer
