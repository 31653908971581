import { get } from 'lib/reduxHelpers/thunks'
import { idTokenTypes } from 'actions/types'

function fetchIdToken() {
  let baseType = idTokenTypes.FETCH_ID_TOKEN
  let url = `${global.config.UI_PROXY_ENDPOINT}/token`

  return get({ url, baseType })
}

export {
  fetchIdToken,
}
