import createReducer from 'lib/reducers/createReducer'

function dataHandler(initialState) {
  return (state, action) => {
    return action.payload || initialState
  }
}

function dataReducer({ initialState, baseType }) {
  let handlers = {}
  let baseTypes = [].concat(baseType || [])

  baseTypes.forEach( baseType => {
    handlers[`${baseType}_FULFILLED`] = dataHandler(initialState)
    handlers[`CLEAR_${baseType}`] = dataHandler(initialState)
  })

  return createReducer(initialState, handlers)
}

export default dataReducer
