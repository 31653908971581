import { combineReducers } from 'redux'

import { reducer as form } from 'redux-form'

import { customerReducer as customer } from 'lib/components/ReduxCustomer'
import billingEntities from 'reducers/billingEntitiesReducer'
import billingParties from 'reducers/billingPartiesReducer'
import creditValidity from 'reducers/creditValidityReducer'
import customerSearch from 'reducers/customerSearchReducer'
import eligiblePlan from 'reducers/eligiblePlanReducer'
import eligiblePlans from 'reducers/eligiblePlansReducer'
import idToken from 'reducers/idTokenReducer'
import loggedIn from 'lib/reducers/loggedInReducer'
import operation from 'reducers/operationReducer'
import operations from 'reducers/operationsReducer'
import organisation from 'reducers/organisationReducer'
import person from 'reducers/personReducer'
import persons from 'reducers/personsReducer'
import profile from 'reducers/profileReducer'
import rating from 'reducers/ratingReducer'
import redirect from 'reducers/redirectReducer'
import relationship from 'reducers/relationshipReducer'
import reservations from 'reducers/reservationsReducer'
import service from 'reducers/serviceReducer'
import services from 'reducers/servicesReducer'
import slotAvailability from 'reducers/slotAvailabilityReducer'
import workOrder from 'reducers/workOrderReducer'
import workOrders from 'reducers/workOrdersReducer'

export default combineReducers({
  billingEntities,
  billingParties,
  creditValidity,
  customer,
  customerSearch,
  eligiblePlan,
  eligiblePlans,
  form,
  idToken,
  loggedIn,
  operation,
  operations,
  organisation,
  person,
  persons,
  profile,
  rating,
  redirect,
  relationship,
  reservations,
  service,
  services,
  slotAvailability,
  workOrder,
  workOrders,
})
