import React from 'react'
import { hot } from 'react-hot-loader'
import 'react-dates/initialize'

import AppBar from 'components/AppBar'
import CustomerContextHandler from 'components/CustomerContextHandler'
import GlobalNav from '@licnz/react-global-nav'
import RedirectParamsHandler from 'components/RedirectParamsHandler'
import Routes from 'components/Routes'
import SentryErrorBoundary from 'lib/components/SentryErrorBoundary'
import TabbedNavigation from 'components/TabbedNavigation'
import ToastNotificationsController from '@licnz/react-toast-notifications'
import VersionManager from 'components/VersionManager'
import { HashRouter as Router } from 'react-router-dom'

import 'assets/scss/global'

function AppRoot() {
  const globalNavLinks = {
    shopLink: global.config.SHOP_FRONTEND_ENDPOINT,
  }

  return (
    <Router>
      <div>
        <VersionManager />
        <GlobalNav {...globalNavLinks} />
        <AppBar />
        <TabbedNavigation />
        <CustomerContextHandler />
        <RedirectParamsHandler />
        <ToastNotificationsController />
        <SentryErrorBoundary>
          <Routes />
        </SentryErrorBoundary>
      </div>
    </Router>
  )
}

export default hot(module)(AppRoot)
