import { get, post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'

import { organisationTypes } from 'actions/types'

function fetchOrganisation(organisationLink) {
  let baseType = organisationTypes.FETCH_ORGANISATION
  let url = proxyUrl({ link: organisationLink })

  return get({ baseType, url, extractPayload: response => response.data.party })
}

function fetchOrganisationForParty(partyLink) {
  let baseType = organisationTypes.FETCH_ORGANISATION_FOR_PARTY
  let searchUrl = `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/parties/match`
  let url = proxyUrl({ link: searchUrl })
  let body = {
    query: {
      match: {
        identifiers: [
          {
            id: partyLink,
            '@type': 'party_link'
          }
        ],
        parties: [
          {
            '@type': 'organisation',
            distance: 1
          }
        ]
      }
    }
  }

  const extractSingleOrganisation = (response) => {
    if(response.data.parties.length === 1){
      return response.data.parties[0]
    }
    else {
      let messageNumber = response.data.parties.length === 0 ? 'No' : 'Multiple'
      throw new Error(`${messageNumber} organisations found for party: ${partyLink}`)
    }
  }

  return post({ baseType, url, body, extractPayload: extractSingleOrganisation })
}

function clearOrganisation() {
  return function(dispatch) {
    dispatch({ type: organisationTypes.CLEAR_FETCH_ORGANISATION_FOR_PARTY })
    dispatch({ type: organisationTypes.CLEAR_FETCH_ORGANISATION })
  }
}

export {
  fetchOrganisation,
  fetchOrganisationForParty,
  clearOrganisation,
}
