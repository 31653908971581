import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { workOrderTypes } from 'actions/types'
import workDefinitionsReducer from './workDefinitionsReducer'

const initialState = null

const baseTypes = [
  workOrderTypes.CANCEL_WORK_ORDER,
  workOrderTypes.CONFIRM_WORK_ORDER,
  workOrderTypes.CREATE_WORK_ORDER,
  workOrderTypes.UPDATE_WORK_ORDER,
  workOrderTypes.FETCH_WORK_ORDER
]

const reducer = combineReducers({
  cancelRequestState: requestStateReducer(workOrderTypes.CANCEL_WORK_ORDER),
  confirmRequestState: requestStateReducer(workOrderTypes.CONFIRM_WORK_ORDER),
  createRequestState: requestStateReducer(workOrderTypes.CREATE_WORK_ORDER),
  updateRequestState: requestStateReducer(workOrderTypes.UPDATE_WORK_ORDER),
  requestState: requestStateReducer(workOrderTypes.FETCH_WORK_ORDER),
  data: dataReducer({ initialState, baseType: baseTypes }),
  workDefinitions: workDefinitionsReducer
})

export default reducer
