import React from 'react'
import { connect } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import { apolloClient } from 'lib/utils/apolloClient'

let apolloClients = {
  baseClient: null,
  eventTimelineClient: null,
}

const getApolloClient = () => apolloClients

const setupClients = idToken => {
  let baseClient = apolloClient(
    `${global.config.SERVICE_INVENTORY_SERVICE_ENDPOINT}/api`,
    idToken
  )
  let eventTimelineClient = apolloClient(
    `${global.config.EVENT_TIMELINE_SERVICE_ENDPOINT}/api/events`,
    idToken
  )
  let labFulfillmentClient = apolloClient(
    `${global.config.LAB_FULFILLMENT_SERVICE_ENDPOINT}/api`,
    idToken
  )
  apolloClients = { baseClient, eventTimelineClient, labFulfillmentClient }
  return apolloClients
}

const ApolloClientWrapper = ({ children, idToken }) => {
  let { baseClient } = setupClients(idToken)
  return baseClient ? (
    <ApolloProvider client={baseClient}>{children}</ApolloProvider>
  ) : null
}

export { ApolloClientWrapper, getApolloClient }
export default connect(state => {
  return {
    idToken: state.idToken.data,
  }
})(ApolloClientWrapper)
