import { useEffect } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import isApprovedRedirect from 'lib/utils/redirectAllowlist'
import { renderError } from '@licnz/react-toast-notifications'
import { setRedirectUrl, setRedirectLabel } from 'actions/redirectActions'

/**
 * This component is responsible for checking the current url for redirect params.
 * If redirect params are found they are written to redux state. The redirect_url
 * param is validated against a allowlist; if it fails validation an error notification
 * is shown to the user.
 */
const RedirectParamsHandler = ({
  location,
  redirectUrl,
  setRedirectUrl,
  setRedirectLabel,
}) => {
  useEffect(() => {
    let params = queryString.parse(location.search)

    if (params.redirect_url) setRedirectUrl(decodeURIComponent(params.redirect_url))
    if (params.redirect_label) setRedirectLabel(decodeURIComponent(params.redirect_label))
  }, [])

  useEffect(() => {
    if (redirectUrl) {
      try {
        if (!isApprovedRedirect(redirectUrl)) {
          renderError({
            message:
              'The provided redirect is not approved for use with this application.',
          })
        }
      } catch (error) {
        renderError({ message: 'There is a problem with the provided redirect.' })
      }
    }
  }, [redirectUrl])

  return null
}

const mapDispatchToProps = {
  setRedirectLabel,
  setRedirectUrl,
}

export { RedirectParamsHandler }
export default compose(
  withRouter,
  connect(
    state => {
      return {
        redirectUrl: state.redirect.url,
      }
    },
    mapDispatchToProps
  )
)(RedirectParamsHandler)
