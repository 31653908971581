const ADMINISTER_JOBS = 'urn:lic:ability:diagnostics:administer_parentage_jobs'
const ANIMAL_SEARCH = 'urn:lic:ability:lab_fulfillment:animal_search'
const OVERRIDE_CAPACITY = 'urn:lic:ability:inventory:schedule_capacity_override'
const VIEW_CUSTOMERS = 'urn:lic:ability:inventory:customers'
const VIEW_WORK_ORDERS = 'urn:lic:ability:inventory:work_orders'

const ALL_ABILITIES = [
  ADMINISTER_JOBS,
  ANIMAL_SEARCH,
  OVERRIDE_CAPACITY,
  VIEW_CUSTOMERS,
  VIEW_WORK_ORDERS,
]

export {
  ADMINISTER_JOBS,
  ALL_ABILITIES,
  ANIMAL_SEARCH,
  OVERRIDE_CAPACITY,
  VIEW_CUSTOMERS,
  VIEW_WORK_ORDERS,
}
