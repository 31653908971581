import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { serviceTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [serviceTypes.FETCH_SERVICE_FULFILLED]: dataHandler,
  [serviceTypes.CLEAR_FETCH_SERVICE]: dataHandler,
  [serviceTypes.SET_SERVICE]: dataHandler,
  [serviceTypes.CLEAR_SET_SERVICE]: dataHandler,
})

export default combineReducers({
  requestState: requestStateReducer(serviceTypes.FETCH_SERVICE),
  data: dataReducer,
})
