import React from 'react'
import { render } from 'react-dom'
import axios from 'axios'

import { ALL_ABILITIES } from 'constants/abilityTypes'

import ApolloClientWrapper from 'components/ApolloClientWrapper'
import AuthHandler from 'components/AuthHandler'
import AppRoot from 'components/AppRoot'
import ConfiguredReactQueryProvider from 'components/ConfiguredReactQueryProvider'
import createStore from './store/createStore'
import initialiseExceptionMonitoring from '../config/exceptionMonitoring'
import withUser from 'lib/components/AbilitiesProvider/withUser'
import { AbilitiesProvider } from 'lib/components/AbilitiesProvider'
import { ConfirmationProvider } from 'lib/components/ConfirmationDialog'
import { Provider } from 'react-redux'
import packageInfo from '../package.json'
const { version } = packageInfo

const store = createStore()
const WrappedAbilitiesProvider = withUser(AbilitiesProvider)

const renderApp = () =>
  render(
    <Provider store={store}>
      <ConfiguredReactQueryProvider>
        <WrappedAbilitiesProvider resourceIdentifiers={ALL_ABILITIES}>
          <ConfirmationProvider>
            <AuthHandler />
            <ApolloClientWrapper>
              <AppRoot />
            </ApolloClientWrapper>
          </ConfirmationProvider>
        </WrappedAbilitiesProvider>
      </ConfiguredReactQueryProvider>
    </Provider>,
    document.querySelector('#root')
  )

const getAppConfig = () => {
  // Set to the current version number to force a re-read of the config when version changes
  return axios.get(`./config.json?version=${version}`).then(response => response.data)
}

getAppConfig().then(config => {
  global.config = config
  initialiseExceptionMonitoring(config)
  renderApp()
})
