import packageInfo from '../package.json'
const { name, version } = packageInfo
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'

function initialise(config) {
  const {
    ENABLE_LOGROCKET,
    ENABLE_SENTRY,
    ENVIRONMENT_NAME,
    LOGROCKET_ID,
    SENTRY_DSN,
  } = config
  const enableLogRocket = ENABLE_LOGROCKET && ENABLE_LOGROCKET === 'yes'
  const enableSentry = ENABLE_SENTRY && ENABLE_SENTRY === 'yes'

  if (enableLogRocket) {
    LogRocket.init(LOGROCKET_ID)
  }

  if (enableSentry) {
    Sentry.init({
      // NOTE: As per Sentry's source mapping docs: 'The release name must be unique within your organization'
      release: `${name}-${version}`,
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT_NAME,
    })

    if (enableLogRocket) {
      // Add a LogRocket session to every crash report and exception
      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL)
        })
      })
    }
  }
}

export default initialise
