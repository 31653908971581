import { redirectTypes } from 'actions/types'

function setRedirectLabel(label) {
  return {
    type: redirectTypes.SET_REDIRECT_LABEL,
    payload: label
  }
}

function setRedirectUrl(url) {
  return {
    type: redirectTypes.SET_REDIRECT_URL,
    payload: url
  }
}

export {
  setRedirectLabel,
  setRedirectUrl
}
