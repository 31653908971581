import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { slotAvailabilityTypes } from 'actions/types'

const initialData = {}
const initialRequestStates = {}

const dataHandler = (state, action) => {
  let { payload, payload: { availability, workType } } = action
  if(!payload) return { ...state }

  return { ...state, [workType]: availability }
}

const dataReducer = createReducer(
  initialData,
  {
    [slotAvailabilityTypes.FETCH_SLOT_AVAILABILITY_FULFILLED]: dataHandler,
    [slotAvailabilityTypes.CLEAR_FETCH_SLOT_AVAILABILITY]: dataHandler,
  }
)

const requestStatesHandler = (state, action) => {
  let { payload, payload: { workType, error } } = action
  if(!payload) return { ...state }

  let errorCheckedAction = { ...action, payload: error || null }
  return {
    ...state,
    [workType]: requestStateReducer(slotAvailabilityTypes.FETCH_SLOT_AVAILABILITY)(state[workType], errorCheckedAction)
  }
}

const requestStatesReducer = createReducer(
  initialRequestStates,
  {
    [slotAvailabilityTypes.FETCH_SLOT_AVAILABILITY]: requestStatesHandler,
    [slotAvailabilityTypes.FETCH_SLOT_AVAILABILITY_FULFILLED]: requestStatesHandler,
    [slotAvailabilityTypes.FETCH_SLOT_AVAILABILITY_REJECTED]: requestStatesHandler,
    [slotAvailabilityTypes.CLEAR_FETCH_SLOT_AVAILABILITY]: requestStatesHandler,
  }
)

const reducer = combineReducers({
  data: dataReducer,
  requestStates: requestStatesReducer,
})

export default reducer
