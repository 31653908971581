import React from 'react'

import { customersPath, workOrdersPath } from 'components/Routes'

import NavigationTabs from '@licnz/react-nav-tabs'
import { Gutters } from '@licnz/react-layout'

import styles from './styles'

const navLinks = [
  {
    link: customersPath(),
    label: 'Customers',
  },
  {
    link: workOrdersPath(),
    label: 'Work orders',
  },
]

const TabbedNavigation = () => {
  return (
    <div className={styles.tabsContainer}>
      <Gutters>
        <NavigationTabs navItems={navLinks} location={location} />
      </Gutters>
    </div>
  )
}

export default TabbedNavigation
