import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { loggedInTypes } from 'lib/actions/types'

const INITIAL_STATE = {
  isLoggedIn: false,
}

function dataHandler(state, action) {
  return { isLoggedIn: action.payload.isLoggedIn }
}

const dataReducer = createReducer(
  INITIAL_STATE,
  {
    [loggedInTypes.FETCH_LOGGED_IN_FULFILLED]: dataHandler,
  }
)

const reducer = combineReducers({
  requestState: requestStateReducer(loggedInTypes.FETCH_LOGGED_IN),
  data: dataReducer
})

export default reducer
