import queryString from 'query-string'

function proxyUrl({ link, params }) {
  const PROXY_ENDPOINT = `${global.config.UI_PROXY_ENDPOINT}/api/proxy`
  let urlToProxy = params ? `${link}?${queryString.stringify(params, { arrayFormat: 'bracket' })}` : link

  return `${PROXY_ENDPOINT}/${encodeURIComponent(urlToProxy)}`
}

export { proxyUrl }
