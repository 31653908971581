import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { href } from '@licnz/js-utils'

import {
  clearCustomer,
  fetchCustomer,
  getAddresses,
  getAgriManager,
  getContactNumbers,
  getCreditValidity,
  getCustomer,
  getOrganisation,
} from 'lib/components/ReduxCustomer'

function useCustomer() {
  const dispatch = useDispatch()
  /***  Get data from redux state ***/
  const customer = useSelector(state => getCustomer(state))
  const creditValidity = useSelector(state => getCreditValidity(state))
  const contactNumbers = useSelector(state => getContactNumbers(state))
  const addresses = useSelector(state => getAddresses(state))
  const agriManager = useSelector(state => getAgriManager(state))
  const organisation = useSelector(state => getOrganisation(state))
  const requestState = useSelector(state => state.customer.operation.requestState)

  const getCustomerData = useCallback(
    partyLink => {
      let linkFromState =
        customer &&
        href({
          links: customer.links,
          rel: 'self',
        })
      if (!linkFromState || linkFromState !== partyLink) {
        dispatch(fetchCustomer(partyLink))
      }
    },
    [customer, dispatch]
  )

  const clearCustomerData = useCallback(
    currentLink => {
      let customerLink =
        customer &&
        href({
          links: customer.links,
          rel: 'self',
        })

      if (customerLink && customerLink !== currentLink) {
        dispatch(clearCustomer())
      }
    },
    [customer, dispatch]
  )

  let data = {
    addresses,
    agriManager,
    contactNumbers,
    creditValidity,
    customer,
    organisation,
  }

  let loading = requestState ? requestState.fetching : null
  let error = requestState ? requestState.errors : null

  return {
    getCustomerData,
    data,
    loading,
    error,
    clearCustomerData,
  }
}

export { useCustomer }
