import { post } from 'lib/reduxHelpers/thunks'
import { href } from '@licnz/js-utils'
import { proxyUrl } from 'lib/utils/request'
import { billingEntitiesTypes } from '../types'

function fetchBillingEntities({ entity, rel }) {
  let link = href({
    links: entity.links,
    rel,
  })

  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/search`,
  })
  let baseType = billingEntitiesTypes.FETCH_BILLING_ENTITIES
  let body = { directives: '_asc', party_identity: link }

  return post({
    baseType,
    url,
    body,
    extractPayload: response => response.data.items
  })
}

function fetchCreditValidity(billingEntities) {
  let billingEntityId = billingEntities.length ? billingEntities[0].id : null

  let baseType = billingEntitiesTypes.FETCH_CREDIT_VALIDITY
  let url = proxyUrl({
    link: `${global.config.ACCOUNT_SERVICE_ENDPOINT}/billing_entities/${billingEntityId}/credit_validity`,
  })

  let body = {
    _type: 'credit_validity',
    payment_method_symbol: 'http://purl.org/goodrelations/v1#ByInvoice',
  }

  return post({
    baseType,
    url,
    body,
  })
}

function clearBillingEntities() {
  return {type: billingEntitiesTypes.CLEAR_FETCH_BILLING_ENTITIES}
}

function clearCreditValidity() {
  return {type: billingEntitiesTypes.CLEAR_FETCH_CREDIT_VALIDITY}
}

export {
  fetchBillingEntities,
  fetchCreditValidity,
  clearBillingEntities,
  clearCreditValidity,
}
