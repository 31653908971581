import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'
import { personTypes } from 'actions/types'

function fetchPerson(link) {
  let baseType = personTypes.FETCH_PERSON
  let url = proxyUrl({ link })

  return get({ baseType, url, extractPayload: response => response.data.party })
}

function clearPerson() {
  return { type: personTypes.CLEAR_FETCH_PERSON }
}

export {
  clearPerson,
  fetchPerson,
}
