import { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import { DELIVERY_PARTY, PLAN_OWNER, RESPONSIBLE_PARTY } from 'constants/predicateUrns'
import { clearCustomer, fetchCustomer } from 'lib/components/ReduxCustomer'
import { fetchPerson, clearPerson } from 'actions/personActions'
import { fetchOperation, clearOperation } from 'actions/operationActions'
import { fetchOrganisation, fetchOrganisationForParty } from 'actions/organisationActions'
import {
  fetchBillingEntities,
  fetchCreditValidity,
  clearBillingEntities,
  clearCreditValidity,
} from 'actions/billingEntitiesActions'
import { renderError } from '@licnz/react-toast-notifications'

// A renderless handler for fetching the current contact person, operation and organisation from the relevant services.
// If context has a work order, fetch from its list of ordering parties. Otherwise try find
// the links in the current url. Possible query params are ?personLink=<link>&organisationLink=<link>
// or ?personLink=<link>&operationLink=<link>.
const CustomerContextHandler = ({
  billingEntitiesRequestState,
  clearBillingEntities,
  clearCreditValidity,
  clearCustomer,
  clearPerson,
  fetchBillingEntities,
  fetchCreditValidity,
  fetchCustomer,
  fetchOrganisation,
  fetchOrganisationForParty,
  fetchPerson,
  location: { search },
  operation,
  organisation,
  workOrder,
}) => {
  useEffect(() => {
    if (search) {
      updateCustomerFromUrl()
    } else if (workOrder) {
      updateFromWorkOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder, search])

  useEffect(() => {
    return clearBillingEntityData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (billingEntitiesRequestState.fetched || billingEntitiesRequestState.fetching)
      return
    if (organisation) {
      getBillingEntityData({ entity: organisation, rel: 'self' })
    } else if (operation) {
      getBillingEntityData({ entity: operation, rel: 'up' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation, operation])

  const getBillingEntityData = entity => {
    fetchBillingEntities(entity)
      .then(({ data }) => {
        fetchCreditValidity(data.items).catch(() => {
          renderError({
            message: `We were unable to find the credit validity for this customer `,
          })
        })
      })
      .catch(() => {
        renderError({ message: `We were unable to find the customer billing entities` })
      })
  }

  const clearBillingEntityData = () => {
    clearCreditValidity()
    clearBillingEntities()
  }

  const updateFromWorkOrder = () => {
    let personParty = workOrder && workOrder.ordering_parties[RESPONSIBLE_PARTY]
    let personLink = personParty && personParty.identifier.identifier
    if (personLink) {
      fetchPerson(personLink).catch(() => {
        renderError({ message: `We were unable to find that Customer` })
      })
    } else {
      clearPerson()
    }

    let organisation = workOrder && workOrder.ordering_parties[PLAN_OWNER]
    let organisationLink = organisation.identifier.identifier
    if (organisationLink) {
      fetchOrganisation(organisationLink).catch(() => {
        renderError({ message: `We were unable to find that Organisation` })
      })
    } else {
      renderError({ message: `No organisation found for this Work Order` })
    }

    let operation = workOrder && workOrder.ordering_parties[DELIVERY_PARTY]
    let operationLink = operation && operation.identifier.identifier
    if (operationLink) {
      fetchCustomer(operationLink)
      if (!organisationLink) {
        fetchOrganisationForParty(operationLink).catch(() => {
          renderError({
            message: `We were unable to find the Organisation for that Operation`,
          })
        })
      }
    } else {
      clearCustomer()
    }
  }

  const updateCustomerFromUrl = () => {
    let { personLink, organisationLink, operationLink } = queryString.parse(search)
    if (personLink) {
      fetchPerson(personLink).catch(() => {
        renderError({ message: `We were unable to find that Person` })
      })
    }
    if (operationLink) {
      fetchCustomer(operationLink)
      if (!organisationLink) {
        fetchOrganisationForParty(operationLink).catch(() => {
          renderError({
            message: `We were unable to find the Organisation for that Operation`,
          })
        })
      }
    }
    if (organisationLink) {
      fetchOrganisation(organisationLink).catch(() => {
        renderError({ message: `We were unable to find that Organisation` })
      })
    }
    if (organisationLink && !operationLink) fetchCustomer(organisationLink)
  }

  // Handler only, renders nothing
  return null
}

const mapDispatchToProps = {
  clearBillingEntities,
  clearCreditValidity,
  clearCustomer,
  clearOperation,
  clearPerson,
  fetchBillingEntities,
  fetchCreditValidity,
  fetchCustomer,
  fetchOperation,
  fetchOrganisation,
  fetchOrganisationForParty,
  fetchPerson,
}

export { CustomerContextHandler }
export default compose(
  withRouter,
  connect(state => {
    return {
      workOrder: state.workOrder.data,
      operation: state.operation.data,
      organisation: state.organisation.data,
      billingEntitiesRequestState: state.billingEntities.requestState,
    }
  }, mapDispatchToProps)
)(CustomerContextHandler)
