const ASSIGN_TO_PARTY = 'urn:lic:predicate:assign_to_party'
const BILL_TO_PARTY = 'urn:lic:predicate:bill_to_party'
const CONTRACT = 'urn:lic:predicate:contract'
const DELIVERY_PARTY = 'urn:lic:predicate:delivery_party'
const PLAN_INITIATOR = 'urn:lic:predicate:plan_initiator'
const PLAN_OWNER = 'urn:lic:predicate:plan_owner'
const RESPONSIBLE_PARTY = 'urn:lic:predicate:responsible_party'

export {
  ASSIGN_TO_PARTY,
  BILL_TO_PARTY,
  CONTRACT,
  DELIVERY_PARTY,
  PLAN_INITIATOR,
  PLAN_OWNER,
  RESPONSIBLE_PARTY,
}
