import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { workOrderTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [workOrderTypes.FETCH_WORK_ORDERS_FULFILLED]: dataHandler,
  [workOrderTypes.CLEAR_FETCH_WORK_ORDERS]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(workOrderTypes.FETCH_WORK_ORDERS),
})

export default reducer
