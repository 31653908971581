import { combineReducers } from 'redux'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import dataReducer from 'lib/reducers/dataReducer'

import { workOrderTypes } from 'actions/types'

const initialState = []

const reducer = combineReducers({
  requestState: requestStateReducer(
    workOrderTypes.FETCH_WORK_ORDER_WORK_DEFINITIONS
  ),
  data: dataReducer({
    initialState,
    baseType: [workOrderTypes.FETCH_WORK_ORDER_WORK_DEFINITIONS]
  })
})

export default reducer
