import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect, generatePath } from 'react-router-dom'
import queryString from 'query-string'

import ProtectedRoute from './ProtectedRoute'
import RequestWrapper from '@licnz/react-request-wrapper'

const Customers = lazy(() =>
  import(/* webpackChunkName: 'Customers' */ 'components/Customers')
)
const WorkOrder = lazy(() =>
  import(/* webpackChunkName: 'WorkOrder' */ 'components/WorkOrder')
)
const WorkOrderEdit = lazy(() =>
  import(/* webpackChunkName: 'WorkOrderEdit' */ 'components/WorkOrderEdit')
)
const WorkOrderNew = lazy(() =>
  import(/* webpackChunkName: 'WorkOrderNew' */ 'components/WorkOrderNew')
)
const WorkOrders = lazy(() =>
  import(/* webpackChunkName: 'WorkOrders' */ 'components/WorkOrders')
)

/**
 * A utility function for working with React Router paths. It allows us to both define
 * routes and build valid links to those routes with the same function, making it easier
 * to link to, search for, and refactor our routes (no more magic strings!)
 *
 * When called with only a `path`, it will return that path. This is useful when defining
 * a route, or creating a link to a simple, non-parameterised route.
 *
 * When called with `routeParams`, it will return a link to the path, substituting the
 * route param values for the placeholders in the path.
 *
 * When called with `queryParams`, it will return a link to the path, including a
 * queryString containing the query params.
 *
 * When called with `referer`, it will return the path as a `location` object containing
 * the referer in its `state` object.
 */
const buildLink = ({ path, routeParams, queryParams, referer }) => {
  let pathname = routeParams ? generatePath(path, routeParams) : path
  let search = queryParams ? `?${queryString.stringify(queryParams)}` : ''

  if (referer) {
    return {
      pathname,
      search,
      state: { referer },
    }
  }

  return `${pathname}${search}`
}

/**
 * Returns the referer from a location object as injected by the `buildLink` function.
 */
const getReferer = location => {
  let { state } = location
  let { referer } = state || {}

  return referer
}

// See `buildLink` above for valid `args`
const customersPath = args => buildLink({ ...args, path: '/customers' })
const workOrderCreatePath = args => buildLink({ ...args, path: '/work_orders/new' })
const workOrderEditPath = args =>
  buildLink({ ...args, path: '/work_orders/work_order/edit' })
const workOrderPath = args => buildLink({ ...args, path: '/work_orders/work_order' })
const workOrdersPath = args => buildLink({ ...args, path: '/work_orders' })

/*
 *  We have two types of routes here:
 *    1. Route - a standard (public) client side route.
 *    2. ProtectedRoute - a custom wrapper on the standard Route component.
 *  The ProtectedRoute should be used for all routes that require the user to be
 *  logged in. It checks the current loggedIn status and either renders the
 *  component at this route, or directs the user into the auth flow.
 */
const Routes = () => (
  <Suspense fallback={<RequestWrapper loading={true} />}>
    <Switch>
      <ProtectedRoute exact path={customersPath()} component={Customers} />
      <ProtectedRoute exact path={workOrderCreatePath()} component={WorkOrderNew} />
      <ProtectedRoute exact path={workOrderEditPath()} component={WorkOrderEdit} />
      <ProtectedRoute exact path={workOrderPath()} component={WorkOrder} />
      <ProtectedRoute exact path={workOrdersPath()} component={WorkOrders} />
      <Redirect from='/' to={customersPath()} />
    </Switch>
  </Suspense>
)

const RootRoute = () => <Route path='/' component={Routes} />

export default RootRoute
export {
  customersPath,
  getReferer,
  workOrderCreatePath,
  workOrderEditPath,
  workOrderPath,
  workOrdersPath,
}
