import parsePath from 'parse-path'
import queryString from 'query-string'
import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import { connect } from 'react-redux'
import { useEffect } from 'react'

import { renderError } from '@licnz/react-toast-notifications'
import { fetchIdToken } from 'actions/idTokenActions'
import { fetchLoggedIn } from 'lib/actions/loggedInActions'
import { getRequestStatus, SUCCESS } from 'lib/selectors/requestStateSelectors'

const AuthHandler = ({
  fetchIdToken,
  fetchLoggedIn,
  idToken,
  idTokenRequestStatus,
  isLoggedIn,
  isLoggedInRequestStatus,
  profile,
}) => {
  useEffect(() => {
    fetchLoggedIn().catch(() => {
      renderError({
        message: 'We were unable to check your logged in status',
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoggedIn && !idToken && !idTokenRequestStatus) {
      fetchIdToken().catch(() => {
        renderError({
          message: 'We were unable to fetch your authentication token',
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, idToken, idTokenRequestStatus])

  useEffect(() => {
    if (idToken && profile) {
      const name = profile.preferred_name || profile.preferred_username
      const decoded = jwt_decode(idToken.token)

      if (global.config.ENABLE_LOGROCKET === 'yes') {
        LogRocket.identify(decoded.sub, { name, jwt_sub: decoded.sub })
      }

      if (global.config.ENABLE_SENTRY === 'yes') {
        Sentry.setUser({
          id: decoded.sub,
          username: profile.preferred_name,
          email: profile.preferred_username,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, profile])

  useEffect(() => {
    if (isLoggedInRequestStatus === SUCCESS && !isLoggedIn) {
      const currentLocation = parsePath(window.location.hash.split('#')[1])
      const currentPath = currentLocation.pathname
      const currentSearch = currentLocation.search
      const currentQueryParams = queryString.parse(currentSearch)

      const params = queryString.stringify({ ...currentQueryParams, path: currentPath })

      const loginUrl = `${global.config.UI_PROXY_ENDPOINT}/proxy/connect/identity?${params}`

      window.location.assign(loginUrl)
    }
  }, [isLoggedInRequestStatus, isLoggedIn])

  return null
}

const mapDispatchToProps = {
  fetchIdToken,
  fetchLoggedIn,
}

export { AuthHandler }
export default connect(state => {
  return {
    idToken: state.idToken.data,
    idTokenRequestStatus: getRequestStatus(state.idToken.requestState),
    isLoggedIn: state.loggedIn.data.isLoggedIn,
    isLoggedInRequestStatus: getRequestStatus(state.loggedIn.requestState),
    profile: state.profile.data,
  }
}, mapDispatchToProps)(AuthHandler)
