function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

const customerTypes = {
  ...typesForBaseType('FETCH_OPERATION'),
  ...typesForBaseType('FETCH_OPERATION_ADDRESSES'),
  ...typesForBaseType('FETCH_ORGANISATION_CONTACT_NUMBERS'),
  ...typesForBaseType('FETCH_ORGANISATION'),
  ...typesForBaseType('FETCH_CREDIT_VALIDITY'),
  ...typesForBaseType('FETCH_BILLING_ENTITIES'),
  ...typesForBaseType('FETCH_AGRI_MANAGER'),
}

export default customerTypes
