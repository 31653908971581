import React, { createContext, useContext, useRef, useState } from 'react'
import ConfirmationDialog from './ConfirmationDialog'

const ConfirmationContext = createContext(Promise.reject)

const useConfirmation = () => useContext(ConfirmationContext)

const ConfirmationProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null)

  const promiseRef = useRef()

  const openConfirmation = options => {
    setConfirmationState(options)
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve, reject }
    })
  }

  const closeConfirmation = () => setConfirmationState(null)

  const handleCancel = () => {
    if (promiseRef.current) {
      promiseRef.current.reject()
    }

    closeConfirmation()
  }

  const handleConfirm = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve()
    }

    closeConfirmation()
  }

  return (
    <>
      <ConfirmationContext.Provider value={openConfirmation} children={children} />
      <ConfirmationDialog
        isOpen={!!confirmationState}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        {...confirmationState}
      />
    </>
  )
}

export { useConfirmation, ConfirmationProvider }
