import React from 'react'
import { connect } from 'react-redux'

import { href } from '@licnz/js-utils'

export default Component => {
  const WithUserHOC = ({ profile, ...restProps }) => {
    let currentParty = profile ? href({ links: profile.links, rel: 'party' }) : null
    let props = { ...restProps, currentParty }
    return <Component {...props} />
  }

  return connect(state => {
    return {
      profile: state.profile.data,
    }
  })(WithUserHOC)
}
