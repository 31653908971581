function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

const billingEntitiesTypes = {
  ...typesForBaseType('FETCH_BILLING_ENTITIES'),
  ...typesForBaseType('FETCH_CREDIT_VALIDITY'),
}

const billingPartiesTypes = typesForBaseType('FETCH_BILLING_PARTIES')

const customerSearchTypes = typesForBaseType('SEARCH_FOR_CUSTOMER')

const eligiblePlanTypes = typesForBaseType('FETCH_ELIGIBLE_PLAN')

const eligiblePlansTypes = typesForBaseType('FETCH_ELIGIBLE_PLANS')

const idTokenTypes = typesForBaseType('FETCH_ID_TOKEN')

const operationsTypes = typesForBaseType('SEARCH_OPERATIONS')

const operationTypes = typesForBaseType('FETCH_OPERATION')

const organisationTypes = {
  ...typesForBaseType('FETCH_ORGANISATION_FOR_PARTY'),
  ...typesForBaseType('FETCH_ORGANISATION'),
}

const personTypes = typesForBaseType('FETCH_PERSON')

const personsTypes = typesForBaseType('SEARCH_PERSONS')

const serviceTypes = {
  ...typesForBaseType('FETCH_SERVICE'),
  ...typesForBaseType('SET_SERVICE'),
}

const servicesTypes = typesForBaseType('LIST_SERVICES')

const profileTypes = typesForBaseType('FETCH_PROFILE')

const ratingTypes = typesForBaseType('FETCH_RATING')

const redirectTypes = {
  SET_REDIRECT_LABEL: 'SET_REDIRECT_LABEL',
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
}

const relationshipTypes = {
  ...typesForBaseType('FETCH_RELATIONSHIP'),
  ...typesForBaseType('CREATE_RELATIONSHIP'),
  ...typesForBaseType('DELETE_RELATIONSHIP'),
}

const reservationsTypes = {
  ...typesForBaseType('UPDATE_RESERVATIONS'),
  ...typesForBaseType('FETCH_RESERVATIONS'),
  CLEAR_RESERVATIONS: 'CLEAR_RESERVATIONS',
}

const slotAvailabilityTypes = {
  FETCH_SLOT_AVAILABILITY: 'FETCH_SLOT_AVAILABILITY',
  FETCH_SLOT_AVAILABILITY_FULFILLED: 'FETCH_SLOT_AVAILABILITY_FULFILLED',
  FETCH_SLOT_AVAILABILITY_REJECTED: 'FETCH_SLOT_AVAILABILITY_REJECTED',
  CLEAR_FETCH_SLOT_AVAILABILITY: 'CLEAR_FETCH_SLOT_AVAILABILITY',
}

const workOrderTypes = {
  ...typesForBaseType('CANCEL_WORK_ORDER'),
  ...typesForBaseType('CONFIRM_WORK_ORDER'),
  ...typesForBaseType('CREATE_WORK_ORDER'),
  ...typesForBaseType('UPDATE_WORK_ORDER'),
  ...typesForBaseType('FETCH_WORK_ORDER'),
  ...typesForBaseType('FETCH_WORK_ORDERS'),
  ...typesForBaseType('FETCH_WORK_ORDER_WORK_DEFINITIONS'),
}

module.exports = {
  billingEntitiesTypes,
  billingPartiesTypes,
  customerSearchTypes,
  eligiblePlansTypes,
  eligiblePlanTypes,
  idTokenTypes,
  operationsTypes,
  operationTypes,
  organisationTypes,
  personsTypes,
  personTypes,
  profileTypes,
  ratingTypes,
  redirectTypes,
  relationshipTypes,
  reservationsTypes,
  servicesTypes,
  serviceTypes,
  slotAvailabilityTypes,
  workOrderTypes,
}
