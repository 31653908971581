import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import queryString from 'query-string'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchProfile } from 'actions/profileActions'
import { getRequestStatus } from 'lib/selectors/requestStateSelectors'
import { renderError } from '@licnz/react-toast-notifications'
import { useHotkeys } from 'react-hotkeys-hook'

import AppNav from './AppNav'
import SearchContainer from './SearchContainer'
import SearchTrigger from './SearchTrigger'
import UserDropdown from '@licnz/react-user-dropdown'

import styles from './styles'

const AppBar = ({
  fetchProfile,
  isLoggedIn,
  location,
  profile,
  profileRequestStatus,
}) => {
  // NOTE: these must be instantiated from within the AppBar component, as we do not have access to `global.config` outside
  const navLinks = [
    {
      id: 'planning',
      url: `${global.config.SERVICE_PLANNING_FRONTEND_ENDPOINT}/#/`,
      label: 'Planning',
      isActiveApp: true,
    },
    {
      id: 'receipting',
      url: `${global.config.SAMPLE_RECEIPTING_FRONTEND_ENDPOINT}/#/`,
      label: 'Receipting',
    },
    {
      id: 'analysis',
      url: `${global.config.DIAGNOSTICS_FRONTEND_ENDPOINT}/#/`,
      label: 'Analysis',
    },
  ]

  const [showSearchBar, setShowSearchBar] = useState(false)
  useHotkeys('ctrl+p', () => setShowSearchBar(true))
  useHotkeys('esc', () => setShowSearchBar(false))

  useEffect(() => {
    loadProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function loadProfile() {
    if (isLoggedIn && !profile && !profileRequestStatus) {
      fetchProfile().catch(err => {
        if (err.response.status === 404) {
          let redirectParams = {
            redirect_url: global.config.SERVICE_PLANNING_FRONTEND_ENDPOINT,
            redirect_label: 'Planning',
          }
          window.location.assign(
            `${
              global.config.LIC_CUSTOMER_ENDPOINT
            }/#/profile/create?${queryString.stringify(redirectParams)}`
          )
        } else {
          renderError({ message: 'We were unable to fetch your profile.' })
        }
      })
    }
  }

  function handleToggleSearchBar() {
    setShowSearchBar(!showSearchBar)
  }

  const renderUserDropdown = () => {
    let name = profile ? profile.preferred_name || profile.preferred_username : null

    return (
      <div className={styles.dropdown}>
        <UserDropdown userName={name} menuOptions={userDropdownOptions} />
      </div>
    )
  }

  const contextParams = {
    redirect_label: encodeURIComponent('Service Planning'),
    redirect_url: encodeURIComponent(
      `${global.config.SERVICE_PLANNING_FRONTEND_ENDPOINT}/#${location.pathname}${location.search}`
    ),
  }

  const profileUrl = `${
    global.config.LIC_CUSTOMER_ENDPOINT
  }/#/profile?${queryString.stringify(contextParams)}`

  const teamsUrl = `${global.config.LIC_CUSTOMER_ENDPOINT}/#/teams`

  const logoutUrl = `${global.config.IDENTITY_FRONTEND_ENDPOINT}/logout?logout_redirect_uri=${global.config.UI_PROXY_ENDPOINT}/logout`

  const userDropdownOptions = [
    { label: 'My Profile', link: profileUrl },
    { label: 'Teams', link: teamsUrl },
    {
      label: 'Log out',
      link: logoutUrl,
    },
  ]

  const { q } = queryString.parse(location.search)

  const headerMenuSecondaryClasses = classnames(styles.headerMenuSecondary, {
    [styles.headerMenuSecondaryHide]: showSearchBar,
    [styles.headerMenuSecondaryShow]: !showSearchBar,
  })
  const overlayClasses = classnames(styles.overlay, {
    [styles.overlayShow]: showSearchBar,
    [styles.overlayHide]: !showSearchBar,
  })

  return (
    <>
      <div className={styles.header}>
        <div className={styles.gutters}>
          <div className={styles.headerContent}>
            <a className={styles.headerLogo} href='/'>
              <img src='/images/logo_lic.svg' alt='LIC Logo' />
            </a>

            <div className={styles.headerMenu}>
              <div className={styles.headerMenuLarge}>
                <SearchContainer
                  onClose={handleToggleSearchBar}
                  q={q || ''}
                  showSearchBar={showSearchBar}
                />
                <AppNav navLinks={navLinks} />
                <div className={headerMenuSecondaryClasses}>
                  <SearchTrigger handleOpen={handleToggleSearchBar} />
                  {renderUserDropdown()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={overlayClasses}></div>
    </>
  )
}

const mapDispatchToProps = {
  fetchProfile,
}

export { AppBar }
export default compose(
  withRouter, // watch the location prop to rerender active nav item on changes
  connect(state => {
    return {
      isLoggedIn: state.loggedIn.data.isLoggedIn,
      profile: state.profile.data,
      profileRequestStatus: getRequestStatus(state.profile.requestState),
    }
  }, mapDispatchToProps)
)(AppBar)
