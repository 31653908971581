import { get } from 'lib/reduxHelpers/thunks'
import { profileTypes } from 'actions/types'

function fetchProfile() {
  let baseType = profileTypes.FETCH_PROFILE
  let url = `${global.config.UI_PROXY_ENDPOINT}/api/profile/for_current_subject`

  return get({ baseType, url })
}

export {
  fetchProfile,
}