function requestHandler(state) {
  return { ...state, fetching: true, fetched: false }
}

function requestRejectedHandler(state, action) {
  return { ...state, fetching: false, fetched: true, errors: action.payload }
}

function requestFulfilledHandler(state) {
  return { ...state, fetching: false, fetched: true, errors: null }
}

function clearRequestHandler() {
  return { ...INITIAL_STATE }
}

import createReducer from 'lib/reducers/createReducer'

const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  errors: null
}

function requestStateReducerForAction(name) {
  let rejected = `${name}_REJECTED`
  let fulfilled = `${name}_FULFILLED`
  let clear = `CLEAR_${name}`

  let handlers = {}
  handlers[name] = requestHandler
  handlers[rejected] = requestRejectedHandler
  handlers[fulfilled] = requestFulfilledHandler
  handlers[clear] = clearRequestHandler

  return createReducer(
    INITIAL_STATE,
    handlers
  )
}

export {
  requestStateReducerForAction as default,
}
