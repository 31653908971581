function isApprovedRedirect(url) {
  const REDIRECT_ALLOWLIST = [
    global.config.DIAGNOSTICS_FRONTEND_ENDPOINT,
    'http://localhost:5024',
  ]

  return REDIRECT_ALLOWLIST.includes(new URL(url).origin)
}

export default isApprovedRedirect
