import React from 'react'
import configuredAxios from 'lib/axios'
import { proxyUrl } from 'lib/utils/request'

import { ReactQueryConfigProvider } from 'react-query'

const reactQueryConfig = {
  queries: {
    queryFn: async key => {
      if (!key) return
      const { data } = await configuredAxios.get(proxyUrl({ link: key }))
      return data
    },
  },
}

const ConfiguredReactQueryProvider = ({ children }) => (
  <ReactQueryConfigProvider config={reactQueryConfig}>
    {children}
  </ReactQueryConfigProvider>
)

export default ConfiguredReactQueryProvider
