import { createSelector } from 'reselect'

const IN_PROGRESS = 'in progress'
const FAILURE = 'failure'
const SUCCESS = 'success'

const getRequestStatus = createSelector(
  [requestState => requestState],
  requestState => {
    let status = null

    if (requestState.fetching) {
      status = IN_PROGRESS
    }
    if (requestState.fetched) {
      status = requestState.errors ? FAILURE : SUCCESS
    }

    return status
  }
)

// Get the combined request state of multiple request staes. Useful for components such as
// Request Wrapper so we can use them on multiple requests without nesting
const getJointRequestState = createSelector(
  [requestStates => requestStates],
  requestStates => {
    return {
      fetching: requestStates.some(r => r.fetching),
      fetched: requestStates.every(r => r.fetched),
      errors: requestStates.reduce((acc, r) => (r.errors ? { ...acc, ...r.errors } : acc), {}),
    }
  }
)

export { getRequestStatus, getJointRequestState, IN_PROGRESS, FAILURE, SUCCESS }
