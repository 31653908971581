import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { eligiblePlansTypes } from 'actions/types'

const initialState = []
const baseType = eligiblePlansTypes.FETCH_ELIGIBLE_PLANS

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType }),
  requestState: requestStateReducer(baseType),
})

export default reducer
