import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/request'

import { operationTypes } from 'actions/types'

function fetchOperation(link) {
  let baseType = operationTypes.FETCH_OPERATION
  let url = proxyUrl({ link })

  return get({ baseType, url, extractPayload: response => response.data.party })
}

function clearOperation() {
  return { type: operationTypes.CLEAR_FETCH_OPERATION }
}

export {
  clearOperation,
  fetchOperation,
}
