import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { operationTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const dataReducer = createReducer(
  INITIAL_STATE,
  {
    [operationTypes.FETCH_OPERATION_FULFILLED]: dataHandler,
    [operationTypes.CLEAR_FETCH_OPERATION]: dataHandler,
  }
)

const reducer = combineReducers({
  requestState: requestStateReducer(operationTypes.FETCH_OPERATION),
  data: dataReducer,
})

export default reducer
