import React, { createContext, useContext, useState } from 'react'
import axios from 'lib/axios'
import { useQuery } from 'react-query'

import { proxyUrl } from 'lib/utils/request'
import { renderError } from '@licnz/react-toast-notifications'

const AbilitiesContext = createContext(Promise.reject)

const queryResourcePermissions = (
  teamQueryLink,
  { currentParty, resourceIdentifiers }
) => {
  let body = {
    _type: 'team_ql',
    resources: resourceIdentifiers.map(resourceIdentifier => ({
      match: 'identifier',
      term: resourceIdentifier,
    })),
    subjects: [{ match: 'identifier', term: currentParty }],
  }
  return axios.post(proxyUrl({ link: teamQueryLink }), body)
}

const AbilitiesProvider = ({ currentParty, resourceIdentifiers, children }) => {
  const [abilities, setAbilities] = useState({ abilities: [], loading: true })

  const teamQueryLink = `${global.config.TEAM_SERVICE_ENDPOINT}/api/query`
  useQuery(
    [teamQueryLink, { currentParty, resourceIdentifiers }],
    queryResourcePermissions,
    {
      enabled: currentParty,
      onSuccess: response => handleSuccess(response),
      onError: error =>
        renderError({
          message: `Failed to load team abilities. Try refreshing ${error.message}`,
        }),
    }
  )

  const handleSuccess = response => {
    let abilities = response.data.items.map(items => items.resource.identifier)
    setAbilities({ abilities: abilities, loading: false })
  }

  return (
    <AbilitiesContext.Provider value={abilities}>{children}</AbilitiesContext.Provider>
  )
}

// Effect to watch the abilities. This is what you should use as it wraps the AbilitiesContext
const useAbilities = ability => {
  // Create a wrapper on the `useContext` hook, bound to your context (for consumers to use)
  const context = useContext(AbilitiesContext)
  const { abilities, loading } = context
  const can = abilities.includes(ability)

  return { can: !loading ? can : false, loading }
}

export { AbilitiesProvider, useAbilities }
