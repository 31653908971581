import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { eligiblePlanTypes } from 'actions/types'

const initialState = null
const baseType = eligiblePlanTypes.FETCH_ELIGIBLE_PLAN

const reducer = combineReducers({
  data: dataReducer({ baseType, initialState }),
  requestState: requestStateReducer(baseType),
})

export default reducer
