import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

function customLink({ apiUrl, idToken }) {
  return ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors && graphQLErrors.length) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri: apiUrl,
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }),
  ])
}

function client({ apiUrl, idToken }) {
  return new ApolloClient({
    link: customLink({ apiUrl, idToken }),
    cache: new InMemoryCache(),
  })
}

export default client
