import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { reservationsTypes } from 'actions/types'

const initialState = []
const baseTypes = [
  reservationsTypes.FETCH_RESERVATIONS,
  reservationsTypes.UPDATE_RESERVATIONS
]

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType: baseTypes }),
  requestState: requestStateReducer(reservationsTypes.FETCH_RESERVATIONS),
  updateRequestState: requestStateReducer(reservationsTypes.UPDATE_RESERVATIONS)
})

export default reducer
